import React, { useState, useEffect  } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { ALL_USERS, ACTIVATE_USER, DECRYPT_CODE } from '../queries'
import {
  useParams
} from "react-router-dom"

import {
  TextField,
  Button
} from '@material-ui/core'

import {useTranslation} from "react-i18next"
/*
import {useTranslation} from "react-i18next"
const [t, i18n] = useTranslation('common')
{t('headers.agendas')}

*/
import ReCAPTCHA from "react-google-recaptcha"

const UserActivation= ({ setError }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  const [ok, setOk] = useState(true)
  //const [code, setCode] = useState(true)
  const [email, setEmail] =useState('')
  const [verified, setVerified] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [executing, setExecuting] = useState(false)
  const [token, setToken] = useState('')
  
  let code = useParams().id
  
  function onChange(value) {
    console.log("Captcha value:", value);
    setToken(value)
    setVerified(!verified)
  }
  
  const decryptCode = useQuery(DECRYPT_CODE,{
    variables: { code }
  })

  const [ activateUser ] = useMutation(ACTIVATE_USER, {
    refetchQueries: [  {query: ALL_USERS} ],
    onError: (error) => {
      setOk(false)
      //setError(error.graphQLErrors[0].message)
      setError(error)
    },
      //setOk('ok')
  })
 
  //useEffect(() => {
  const submit = async (event) => {
    if(code.length>5){
    
      const emaili = await decryptCode
      await setEmail(emaili.data.decryptCode.message)
      console.log(emaili.data.decryptCode.message)
      
      activateUser({
        variables: { email:emaili.data.decryptCode.message, activated:true  }
      })
    }
  //}, [code, activateUser])
    setSubmitted(true)
  }
  const stylea = {
    background: 'white',
    opacity: 0.9
  }
  //useEffect(() => {
   // setSectionid(sectionss)
  //}, [])
  if(ok&&!submitted){
        return(
          <div>
            <h2>{t('headers.useractivation')}</h2>
            <br /><br />
            <div align='center'>
              <ReCAPTCHA sitekey="6Lf6wLIaAAAAAORH1jwBkpv1NzwvaBYa9mpn39GP" onChange={onChange} />
            </div>
            <br />
            <Button variant="contained" style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} type="submit"
            disabled={(!verified)}
            executing={executing}
            submitted={submitted}
            onClick = {submit}
        >
          {submitted ? t('common.done') : executing ? 'Verifying' : t('common.activate')}
        </Button>
            
            <br /><br />
            <a href='/'>{t('common.tostart')}</a>
          </div>
        )
    }if(!ok){
      return (
        <div>
          <h2>{t('headers.useractivation')}</h2>
          <br />
          {t('common.activationfailed')}
          <br /><br />
          <a href='/'>{t('common.tostart')}</a>
        </div>
    )
  }else{
    return (
      <div>
        <h2>{t('headers.useractivation')}</h2>
        <br />
        {t('common.activationok')}
        <br /><br />
        <a href='/'>{t('common.tostart')}</a>
      </div>
    )
  }
}

export default UserActivation