import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import ReCAPTCHA from "react-google-recaptcha"
import { ALL_USERS, CREATE_USER, SEND_EMAIL, GET_ACTIVATIONCODE } from '../queries'
import {
  useParams
} from "react-router-dom"

import {
  TextField,
  Button,
  Checkbox,
  Select,
  Link,
  MenuItem
} from '@material-ui/core'
import { Link as RouterLink } from "react-router-dom"
import {useTranslation} from "react-i18next"
import ReactGA from 'react-ga'
ReactGA.initialize('G-9X7771WZ11')
ReactGA.pageview(window.location.pathname + window.location.search)
/*
import {useTranslation} from "react-i18next"
const [t, i18n] = useTranslation('common')
{t('headers.agendas')}

*/
//const crypto = require('crypto')
//const algorithm = 'aes-256-ctr'
//const secretKey = config.ACTIVATE_SECRET
//const iv = crypto.randomBytes(16)
//const config = require('./utils/config')
const UserRegistration = ({ setError }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [postnumber, setPostnumber] = useState('')
  const [postoffice, setPostoffice] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [repassword, setRepassword] = useState('')
  const [activated, setActivated] = useState(false)
  const [datetime, setDatetime] = useState('')
  const [type, setType] = useState('')
  const [gender, setGender] = useState('')
  const [born, setBorn] = useState('')
  const [municipality, setMunicipality] = useState('')
  const [country, setCountry] = useState('Suomi')
  const [accepted, setAccepted] = useState(false)
  const [token, setToken] = useState('')
  const [verified, setVerified] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [executing, setExecuting] = useState(false)
  const [acceptedtermsofuse, setAcceptedtermsofuse] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [rendered, setRendered] = useState(false)
  const [invisible, setInvisible] = useState(false)
  const [emailerror, setEmailerror] = useState(false)
  const [codea, setCodea] = useState('')
  const [code, setCode] = useState('fsfMHFTQ78Fco')

  const bcryptjs = require('bcryptjs')

  const kunnat =[
    'Akaa',
'Alajärvi',
'Alavieska',
'Asikkala',
'Masku',
'Askola',
'Aura',
'Brändö',
'Kemiönsaari',
'Eckerö',
'Enonkoski',
'Enontekiö',
'Espoo',
'Eura',
'Evijärvi',
'Finström',
'Forssa',
'Föglö',
'Geta',
'Haapajärvi',
'Haapavesi',
'Hailuoto',
'Salo',
'Halsua',
'Hamina',
'Hammarland',
'Hankasalmi',
'Hanko',
'Harjavalta',
'Hartola',
'Hattula',
'Hausjärvi',
'Heinola',
'Heinävesi',
'Helsinki',
'Kalajoki',
'Hirvensalmi',
'Hollola',
'Huittinen',
'Humppila',
'Hyrynsalmi',
'Hyvinkää',
'Hämeenkyrö',
'Hämeenlinna',
'Iisalmi',
'Iitti',
'Ikaalinen',
'Ilmajoki',
'Ilomantsi',
'Imatra',
'Inari',
'Lappi',
'Inkoo',
'Isojoki',
'Isokyrö',
'Janakkala',
'Järvenpää',
'Joensuu',
'Jokioinen',
'Jomala',
'Joroinen',
'Joutsa',
'Juuka',
'Juupajoki',
'Juva',
'Jyväskylä',
'Jämijärvi',
'Jämsä',
'Kaavi',
'Kajaani',
'Kangasala',
'Kangasniemi',
'Kankaanpää',
'Kannonkoski',
'Kannus',
'Karijoki',
'Karkkila',
'Sastamala',
'Karstula',
'Karvia',
'Kaskinen',
'Kauhajoki',
'Kauhava',
'Kauniainen',
'Kaustinen',
'Keitele',
'Kemi',
'Kemijärvi',
'Keminmaa',
'Kempele',
'Kerava',
'Keuruu',
'Kihniö',
'Kinnula',
'Kirkkonummi',
'Kitee',
'Kittilä',
'Kiuruvesi',
'Kivijärvi',
'Kokemäki',
'Kokkola',
'Kolari',
'Konnevesi',
'Kontiolahti',
'Korsnäs',
'Koski Tl',
'Kotka',
'Kouvola',
'Kristiinankaupunki',
'Kruunupyy',
'Kuhmo',
'Kuhmoinen',
'Ii',
'Kumlinge',
'Kuopio',
'Kuortane',
'Kurikka',
'Kustavi',
'Kuusamo',
'Kyyjärvi',
'Kärkölä',
'Kärsämäki',
'Kökar',
'Säkylä',
'Lahti',
'Laihia',
'Laitila',
'Lapinjärvi',
'Lapinlahti',
'Lappajärvi',
'Lappeenranta',
'Lapua',
'Laukaa',
'Lemi',
'Lemland',
'Lempäälä',
'Leppävirta',
'Lestijärvi',
'Lieksa',
'Lieto',
'Liminka',
'Liperi',
'Lohja',
'Loimaa',
'Loppi',
'Loviisa',
'Luhanka',
'Lumijoki',
'Lumparland',
'Luoto',
'Luumäki',
'Eurajoki',
'Maalahti',
'Maarianhamina',
'Marttila',
'Merijärvi',
'Merikarvia',
'Miehikkälä',
'Mikkeli',
'Muhos',
'Multia',
'Muonio',
'Mustasaari',
'Muurame',
'Mynämäki',
'Myrskylä',
'Mäntsälä',
'Mäntyharju',
'Naantali',
'Nakkila',
'Parainen',
'Nivala',
'Nokia',
'Nousiainen',
'Nurmes',
'Nurmijärvi',
'Närpiö',
'Orimattila',
'Oripää',
'Orivesi',
'Oulainen',
'Oulu',
'Outokumpu',
'Padasjoki',
'Paimio',
'Paltamo',
'Parikkala',
'Parkano',
'Pedersören kunta',
'Pelkosenniemi',
'Pello',
'Perho',
'Pertunmaa',
'Petäjävesi',
'Pieksämäki',
'Pielavesi',
'Pietarsaari',
'Pihtipudas',
'Kaarina',
'Pirkkala',
'Polvijärvi',
'Pomarkku',
'Pori',
'Pornainen',
'Porvoo',
'Posio',
'Pudasjärvi',
'Pukkila',
'Punkalaidun',
'Puolanka',
'Puumala',
'Pyhtää',
'Pyhäjoki',
'Pyhäjärvi',
'Pyhäntä',
'Pyhäranta',
'Pälkäne',
'Pöytyä',
'Raahe',
'Raasepori',
'Raisio',
'Rantasalmi',
'Ranua',
'Rauma',
'Rautalampi',
'Rautavaara',
'Rautjärvi',
'Reisjärvi',
'Riihimäki',
'Ristijärvi',
'Rovaniemi',
'Ruokolahti',
'Ruovesi',
'Rääkkylä',
'Saarijärvi',
'Salla',
'Saltvik',
'Sauvo',
'Savitaipale',
'Savonlinna',
'Savukoski',
'Seinäjoki',
'Sievi',
'Siikainen',
'Siikajoki',
'Siikalatva',
'Siilinjärvi',
'Simo',
'Sipoo',
'Siuntio',
'Sodankylä',
'Soini',
'Somero',
'Sonkajärvi',
'Sotkamo',
'Sottunga',
'Sulkava',
'Sund',
'Suomussalmi',
'Suonenjoki',
'Sysmä',
'Taipalsaari',
'Taivalkoski',
'Taivassalo',
'Tammela',
'Tampere',
'Tervo',
'Tervola',
'Teuva',
'Tohmajärvi',
'Toholampi',
'Toivakka',
'Tornio',
'Turku',
'Tuusniemi',
'Tuusula',
'Tyrnävä',
'Alavus',
'Ulvila',
'Urjala',
'Utajärvi',
'Utsjoki',
'Uurainen',
'Uusikaarlepyy',
'Uusikaupunki',
'Vaala',
'Vaasa',
'Rusko',
'Valkeakoski',
'Vantaa',
'Varkaus',
'Vehmaa',
'Vesanto',
'Vesilahti',
'Veteli',
'Vieremä',
'Vihti',
'Viitasaari',
'Mänttä-Vilppula',
'Vimpeli',
'Virolahti',
'Virrat',
'Vårdö',
'Vöyri',
'Ylitornio',
'Ylivieska',
'Ylöjärvi',
'Ypäjä',
'Ähtäri',
'Äänekoski'
  ]

  
  const [ addUser ] = useMutation(CREATE_USER, {
    refetchQueries: [  {query: ALL_USERS} ],
    onError: (error) => {
      console.log('virhe käyttäjän luonnissa:' + error)
      //setError(error.graphQLErrors[0].message)
      setError(error)
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })
  const [ sendEmail ] = useMutation(SEND_EMAIL, {
    onError: (error) => {
      console.log('virhe meilin lähetyksessä:' +error)
      //setError(error.graphQLErrors[0].message)
      setEmailerror(true)
      //setError(error)
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })
  
  const getActivation = useQuery(GET_ACTIVATIONCODE,{
    variables: { email }
  })

  useEffect(() => {
    const token = getActivation
    if(token.data !== undefined){
      console.log(token.data.getActivationcode.message)
      setCodea(token.data.getActivationcode.message)
    }
    //.previousData.getActivationcode.message)
    
    
  }, [email, getActivation])
  // eslint-disable-next-line
  let userss=''
  userss = useParams().id

  
  const uusi = async (event) => {
    console.log(event)
    const aika = Date.now()
    const pvm = new Date(aika)
    setDatetime(pvm.toString())
    setEmail(event)
    
    //setUser(sectionss)
  }
  
  

  const submit = async (event) => {
    event.preventDefault()
    if((password===repassword) && accepted){
      //const saltRounds = 17
      const aika = Date.now()
      const pvm = new Date(aika)
      setDatetime(pvm.toString())
      setAccepted(pvm.toString())
      var salt = bcryptjs.genSaltSync(12)
      const passwordhash = await bcryptjs.hash(password, salt)
      console.log(passwordhash)
     /* const user = new User({
        username: body.username,
        name: body.name,
        passwordHash,
      })
    
      const savedUser = await user.save()
    
      response.json(savedUser)
      */
      //const passwordhash = password
      //const getCode = async () => { 
    //    return get
      //} 
      addUser({
        variables: { name, email, passwordhash, address, gender, country, municipality:postoffice, born, postnumber, postoffice, datetime, phone, activated, type, code, acceptedtermsofuse: datetime }
      })
      
      
      sendEmail({
        variables: { to:email, header:'Kunta.live rekisteröityminen / registration / registrering', text:'Aktivoi tunnuksesi klikkaamalla / Activate your account by clicking / Aktivera ditt konto genom att klicka på / https://kunta.live/activation/'+codea}
        //'Kiitos rekisteröitymisestä / Thank you for registering / Tack för din registrering'
      })
      console.log('MEILATTU')
      //sectionid: sectionid.length > 0 ? sectionid : 'tyhjä'
      setDatetime('')
      setName('')
      setEmail('')
      setPassword('')
      setAddress('')
      setPostnumber('')
      setPostoffice('')
      setPhone('')
      setRepassword('')
      setType('')
      setActivated('')
      setBorn('')
      setGender('')
      setMunicipality('')
      setSubmitted(true)
      //setSectionid(sectionss)
    }else{
      alert(t('common.passwordmismatch'))
    }
  }
  const stylea = {
    background: 'white',
    opacity: 0.9
  }
  function onChange(value) {
    console.log("Captcha value:", value);
    setToken(value)
    setVerified(!verified)
  }

  /*const renderRecaptcha = () => {
    if (this.captcha) {
      this.captcha.renderExplicitly();
    }
    this.setState({ rendered: true });
  }*/
  //useEffect(() => {
   // setSectionid(sectionss)
  //}, [])
  if(emailerror){
    return(<div>
        <h2>{t('headers.registration')}</h2>
        {t('common.emailerror')}
        <br /><br />
        <Link
          {...{
            component: RouterLink,
            to: '/',
            style: { textDecoration: "none" },
          }}
        >
          {t('common.tostart')}
          </Link>
    </div>
    )
    }
  if(submitted){
    return(<div>
        <h2>{t('headers.registration')}</h2>
        {t('common.registrationok')}
        <br /><br />
        <a href='/'>{t('common.tostart')}</a>
    </div>
    )
  }else{
    return (
      <div>
        <h2>{t('headers.registration')}</h2>

        <form onSubmit={submit}>
        {t('common.name')} <TextField
            value={name}
            onChange={({ target }) => setName(target.value)}
          /><br />
          {t('common.email')}  <TextField
            value={email}
            onChange={({ target }) => uusi(target.value)}
          /><br />
          {t('common.password')} <TextField type='password'
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          /><br />
          {t('common.repassword')}  <TextField type='password'
            value={repassword}
            onChange={({ target }) => setRepassword(target.value)}
          /><br />
          {t('common.address')}  <TextField
            value={address}
            onChange={({ target }) => setAddress(target.value)}
          /><br />
          {t('common.postnumber')}  <TextField
            value={postnumber}
            onChange={({ target }) => setPostnumber(target.value)}
          /><br />
          {t('common.postoffice')}  
          <Select labelId="postoffice" id="postoffice" value={postoffice} onChange={({ target }) => setPostoffice(target.value)}>
            <MenuItem value={''}>ei valittu</MenuItem>
            {kunnat.map(k => (
            <MenuItem value={k}>{k}</MenuItem>
            ))}
          </Select>
          <br />
          {t('common.born')} 
          <Select labelId="born" id="born" value={born} onChange={({ target }) => setBorn(target.value)}>
            <MenuItem value={''}>ei valittu</MenuItem>
            <MenuItem value={'2003'}>2003</MenuItem>
            <MenuItem value={'2002'}>2002</MenuItem>
            <MenuItem value={'2001'}>2001</MenuItem>
            <MenuItem value={'2000'}>2000</MenuItem>
            <MenuItem value={'1999'}>1999</MenuItem>
            <MenuItem value={'1998'}>1998</MenuItem>
            <MenuItem value={'1997'}>1997</MenuItem>
            <MenuItem value={'1996'}>1996</MenuItem>
            <MenuItem value={'1995'}>1995</MenuItem>
            <MenuItem value={'1994'}>1994</MenuItem>
            <MenuItem value={'1993'}>1993</MenuItem>
            <MenuItem value={'1992'}>1992</MenuItem>
            <MenuItem value={'1991'}>1991</MenuItem>
            <MenuItem value={'1990'}>1990</MenuItem>
            <MenuItem value={'1989'}>1989</MenuItem>
            <MenuItem value={'1988'}>1988</MenuItem>
            <MenuItem value={'1987'}>1987</MenuItem>
            <MenuItem value={'1986'}>1986</MenuItem>
            <MenuItem value={'1985'}>1985</MenuItem>
            <MenuItem value={'1984'}>1984</MenuItem>
            <MenuItem value={'1983'}>1983</MenuItem>
            <MenuItem value={'1982'}>1982</MenuItem>
            <MenuItem value={'1981'}>1981</MenuItem>
            <MenuItem value={'1980'}>1980</MenuItem>
            <MenuItem value={'1979'}>1979</MenuItem>
            <MenuItem value={'1978'}>1978</MenuItem>
            <MenuItem value={'1977'}>1977</MenuItem>
            <MenuItem value={'1976'}>1976</MenuItem>
            <MenuItem value={'1975'}>1975</MenuItem>
            <MenuItem value={'1974'}>1974</MenuItem>
            <MenuItem value={'1973'}>1973</MenuItem>
            <MenuItem value={'1972'}>1972</MenuItem>
            <MenuItem value={'1971'}>1971</MenuItem>
            <MenuItem value={'1970'}>1970</MenuItem>
            <MenuItem value={'1969'}>1969</MenuItem>
            <MenuItem value={'1968'}>1968</MenuItem>
            <MenuItem value={'1967'}>1967</MenuItem>
            <MenuItem value={'1966'}>1966</MenuItem>
            <MenuItem value={'1965'}>1965</MenuItem>
            <MenuItem value={'1964'}>1964</MenuItem>
            <MenuItem value={'1963'}>1963</MenuItem>
            <MenuItem value={'1962'}>1962</MenuItem>
            <MenuItem value={'1961'}>1961</MenuItem>
            <MenuItem value={'1960'}>1960</MenuItem>
            <MenuItem value={'1959'}>1959</MenuItem>
            <MenuItem value={'1958'}>1958</MenuItem>
            <MenuItem value={'1957'}>1957</MenuItem>
            <MenuItem value={'1956'}>1956</MenuItem>
            <MenuItem value={'1955'}>1955</MenuItem>
            <MenuItem value={'1954'}>1954</MenuItem>
            <MenuItem value={'1953'}>1953</MenuItem>
            <MenuItem value={'1952'}>1952</MenuItem>
            <MenuItem value={'1951'}>1951</MenuItem>
            <MenuItem value={'1950'}>1950</MenuItem>
            <MenuItem value={'1949'}>1949</MenuItem>
            <MenuItem value={'1948'}>1948</MenuItem>
            <MenuItem value={'1947'}>1947</MenuItem>
            <MenuItem value={'1946'}>1946</MenuItem>
            <MenuItem value={'1945'}>1945</MenuItem>
            <MenuItem value={'1944'}>1944</MenuItem>
            <MenuItem value={'1943'}>1943</MenuItem>
            <MenuItem value={'1942'}>1942</MenuItem>
            <MenuItem value={'1941'}>1941</MenuItem>
            <MenuItem value={'1940'}>1940</MenuItem>
            <MenuItem value={'1939'}>1939</MenuItem>
            <MenuItem value={'1938'}>1938</MenuItem>
            <MenuItem value={'1937'}>1937</MenuItem>
            <MenuItem value={'1936'}>1936</MenuItem>
            <MenuItem value={'1935'}>1935</MenuItem>
            <MenuItem value={'1934'}>1934</MenuItem>
            <MenuItem value={'1933'}>1933</MenuItem>
            <MenuItem value={'1932'}>1932</MenuItem>
            <MenuItem value={'1931'}>1931</MenuItem>
            <MenuItem value={'1930'}>1930</MenuItem>
            <MenuItem value={'1929'}>1929</MenuItem>
            <MenuItem value={'1928'}>1928</MenuItem>
            <MenuItem value={'1927'}>1927</MenuItem>
            <MenuItem value={'1926'}>1926</MenuItem>
            <MenuItem value={'1925'}>1925</MenuItem>
            <MenuItem value={'1924'}>1924</MenuItem>
            <MenuItem value={'1923'}>1923</MenuItem>
            <MenuItem value={'1922'}>1922</MenuItem>
            <MenuItem value={'1921'}>1921</MenuItem>
            <MenuItem value={'1920'}>1920</MenuItem>
            <MenuItem value={'1919'}>1919</MenuItem>
            <MenuItem value={'1918'}>1918</MenuItem>
            <MenuItem value={'1917'}>1917</MenuItem>
            <MenuItem value={'1916'}>1916</MenuItem>
            <MenuItem value={'1915'}>1915</MenuItem>
            <MenuItem value={'1914'}>1914</MenuItem>
            <MenuItem value={'1913'}>1913</MenuItem>
            <MenuItem value={'1912'}>1912</MenuItem>
            <MenuItem value={'1911'}>1911</MenuItem>
            <MenuItem value={'1910'}>1910</MenuItem>
          </Select>
          <br />
          {t('common.gender')}  
          <Select labelId="gender" id="gender" value={gender} onChange={({ target }) => setGender(target.value)}>
            <MenuItem value={'ei valitt'}>ei valittu</MenuItem>
            <MenuItem value={'nainen'}>Nainen</MenuItem>
            <MenuItem value={'mies'}>Mies</MenuItem>
            <MenuItem value={'muu'}>Muu</MenuItem>
          </Select>
          <br />
          {t('common.phone')}  <TextField
            value={phone}
            onChange={({ target }) => setPhone(target.value)}
          /><br /><br />
          <a href="/termsofuse" target='0'>{t('termsofuse.header')}</a>
          <br />
          {t('common.acceptterms')}  <Checkbox
            value={accepted}
            onChange={({ target }) => setAccepted(!accepted)}
          />
          <br /><br />
          <div align='center'>
            <ReCAPTCHA sitekey="6Lf6wLIaAAAAAORH1jwBkpv1NzwvaBYa9mpn39GP" onChange={onChange} />
          </div>
          <br />
          <Button variant="contained" style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} type="submit"
            disabled={(!verified)}
            executing={executing}
            submitted={submitted}
        >
          {submitted ? t('common.done') : executing ? 'Verifying' : t('common.send')}
        </Button>
        </form>
      </div>
    )
  }
}

export default UserRegistration