import React, { useState, useEffect } from 'react'
import { useMutation, useLazyQuery, useQuery } from '@apollo/client'
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  IconButton,
  Drawer,
  Link,
  MenuItem,
} from "@material-ui/core"
import { Link as RouterLink } from "react-router-dom"
import { LOGIN, FIND_USER, SEND_EMAIL, GET_ACTIVATIONCODE, SAVE_CODE } from '../queries'
import {
  TextField,
  Button
} from '@material-ui/core'
import {useTranslation} from "react-i18next"
/*
import {useTranslation} from "react-i18next"
const [t, i18n] = useTranslation('common')
{t('headers.agendas')}

*/
import ReCAPTCHA from "react-google-recaptcha"
import ReactGA from 'react-ga'
ReactGA.initialize('G-9X7771WZ11')
ReactGA.pageview(window.location.pathname + window.location.search)

const ForgetPassword = ({ setUsertype, setError, setToken, onLogin, log, setLogout, logout, client }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  //const history = useHistory()
  //const bcryptjs = require('bcryptjs')
  const [emailerror, setEmailerror] = useState(false)
  const [usererror, setUsererror] = useState(false)
  const [codeerror, setCodeerror] = useState(false)
  const [getPerson, resultt] = useLazyQuery(FIND_USER)  
  // eslint-disable-next-line 
  const [person, setPerson] = useState(null)
  const [token, setTokeni] = useState('')
  const [verified, setVerified] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [executing, setExecuting] = useState(false)
  
  const [code, setCode] = useState('')

  function rnd() {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let ran = ''
    for(let i=0; i<8;i=i+1){
      let ch= chars.charAt(Math.floor(Math.random() * chars.length))
      ran = ran.concat(ch)
    }
    return ran
  }
  
  const [rndnum, setRndnum] = useState(rnd())
  console.log(rndnum)
  function onChange(value) {
    console.log("Captcha value:", value);
    setTokeni(value)
    setVerified(!verified)
  }

  
  // eslint-disable-next-line
  const showPerson = (email) => {    
    getPerson({ 
      variables: { emailToSearch: email } 
    })  
  }
  const [ sendEmail ] = useMutation(SEND_EMAIL, {
    onError: (error) => {
      console.log('virhe meilin lähetyksessä:' +error)
      setEmailerror(true)
      //setError(error.graphQLErrors[0].message)
      //setError(error)
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })
  const [ saveCode ] = useMutation(SAVE_CODE, {
    onError: (error) => {
      console.log('virhe koodin tallennuksessa:' +error)
      //setError(error.graphQLErrors[0].message)
      setCodeerror(true)
      setError(error)
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })
  useEffect(() => {
    if (resultt.data) {  
      setUsertype(resultt.data.findUser['type'])
      localStorage.setItem('usertype', resultt.data.findUser['type'])
    }
    // eslint-disable-next-line
  }, [resultt])
  console.log(person)
  let aika = Date.now()
  aika=aika+60*60000
  const pvm = new Date(aika).toString()
  //console.log(pvm)
  const getActivation = useQuery(GET_ACTIVATIONCODE,{
    variables: { email: email+'|||||'+pvm }
  })

  const [ login,result ] = useMutation(LOGIN, {
    onError: (error) => {
      setError(error.graphQLErrors[0].message)
    }
  })
  // eslint-disable-next-line
  let loggedin = localStorage.getItem('daas-user-token')
 
  useEffect(() => {
    const token = getActivation
    if(token.data !== undefined){
      console.log(token.data.getActivationcode.message)
      setCode(token.data.getActivationcode.message)
    }
    //.previousData.getActivationcode.message)
    
    
  }, [email, getActivation])
  useEffect(() => {
    
    if ( result.data ) {
      const token = result.data.login.value
      console.log(result.data.login)
      setToken(token)
      localStorage.setItem('daas-user-token', token)
      localStorage.setItem('email', email)
    }
  }, [result.data]) // eslint-disable-line

  

  const submit = async (event) => {
    event.preventDefault()
    //const saltRounds = 17
    //const passwordhash = await bcryptjs.hash(password, saltRounds)
    //const passwordhash=password
    //var salt = bcryptjs.genSaltSync(12)
    //const passwordhash = await bcryptjs.hash(password, salt)
    //console.log(passwordhash)
    if(rndnum.length>3){
      try {
        saveCode({
          variables: { email, code:rndnum}
        })
      } catch (error) {
        setError(error)
        setUsererror(true)
      }
    }else{
        setUsererror(true)
      }
    try {
      sendEmail({
        variables: { to:email, header:'Kunta.live -  Resetoi salasanasi / Reset your password / Återställ ditt lösenord', text:'Resetoi salasanasi käyttämällä koodia: '+rndnum+' / Reset your password using the code: '+rndnum+' / Återställ ditt lösenord med koden: '+rndnum+' Osoitteessa/ At / På / https://kunta.live/newpassword/'+code}
        //'Kiitos rekisteröitymisestä / Thank you for registering / Tack för din registrering'
      })
    } catch (error) {
      setError(error)
    }


    
    //event.preventDefault()
    setSubmitted(true)
    //onLogin('oleppani')
    //history.push('/')
  }
  
  /*let rnd = Math.floor(Math.random() * 1000000) + 1
  console.log(rndnum)*/
  //lähetetään urlissa koodi jossa kryptattu aikaleima ja meiliosoite, lisäksi lähetetään koodi, tietokantaan kirjoitetaan koodi.linkki voimassa 60min. avattaessa tarkastetaan että aikaa jäljellä, koodi täsmää ja sen jälkeen syötetään salasana kaksi kertaan ja tallennetaan kantaan

  if(emailerror){
     return(<div>
              <h2>{t('headers.forget')}</h2>
              {t('common.emailerror')}
              <br /><br />
              <a href='/'>{t('common.tostart')}</a>
          </div>
          )
  }
  if(usererror){
     return(<div>
              <h2>{t('headers.forget')}</h2>
              {t('common.usererror')}
              <br /><br />
              <a href='/'>{t('common.tostart')}</a>
          </div>
          )
  }
  if(codeerror){
     return(<div>
              <h2>{t('headers.forget')}</h2>
              {t('common.usererror')}
              <br /><br />
              <a href='/'>{t('common.tostart')}</a>
          </div>
          )
  }
  if(submitted){
        return(<div>
            <h2>{t('headers.forget')}</h2>
            {t('common.formok')}
            <br /><br />
            <a href='/'>{t('common.tostart')}</a>
        </div>
        )
      }else{
        return (
        <div>
          <form onSubmit={submit}>
            <h3>{t('headers.forget')}</h3>
            <div>
            {t('common.forget')}
            <br /><br />
            {t('common.email')} <TextField
                value={email}
                onChange={({ target }) => setEmail(target.value)}
              />
            </div>
            <br />
            <div align='center'>
              <ReCAPTCHA sitekey="6Lf6wLIaAAAAAORH1jwBkpv1NzwvaBYa9mpn39GP" onChange={onChange} />
            </div>
              <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} type='submit'
              disabled={(!verified)}
              executing={executing}
              submitted={submitted} >{submitted ? t('common.done') : executing ? 'Verifying' : t('common.order')}</Button>
            <br /><br />
        <Link
          {...{
            component: RouterLink,
            to: '/register',
            style: { textDecoration: "none" },
          }}
        >
          {t('common.registration')}
          </Link>
  
          </form>
        </div>
      )
    }
}

export default ForgetPassword