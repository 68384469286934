import React, { useState, useEffect } from 'react'
import { useMutation, useLazyQuery, useQuery } from '@apollo/client'

import { LOGIN, FIND_USER, SEND_EMAIL, EDIT_PASSWORD, DECRYPT_CODE, GET_USERPASSCODE } from '../queries'
import {
  TextField,
  Button
} from '@material-ui/core'
import {useTranslation} from "react-i18next"
/*
import {useTranslation} from "react-i18next"
const [t, i18n] = useTranslation('common')
{t('headers.agendas')}

*/
import ReCAPTCHA from "react-google-recaptcha"
import {
  useParams
} from "react-router-dom"
import { LensTwoTone } from '@material-ui/icons'
const bcryptjs = require('bcryptjs')


const NewPassword = ({ setUsertype, setError, setToken, onLogin, log, setLogout, logout, client }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  const [codea, setCodea] = useState('')
  const [code, setCode] = useState('')
  const [dbcode, setDbcode] = useState('')
  const [codeok, setCodeok] = useState(false)
  const [codesubmitted, setCodesubmitted] = useState(false)
  const [password, setPass] = useState('')
  const [repass, setRepass] = useState('')
  const [email, setEmail] = useState('')
  const [pvm, setPvm] = useState('')
  const [accepted, setAccepted] = useState(false)  
  //const history = useHistory()
  //const bcryptjs = require('bcryptjs')
 
  const [getPerson, resultt] = useLazyQuery(FIND_USER)  
  // eslint-disable-next-line 
  const [person, setPerson] = useState(null)
  const [token, setTokeni] = useState('')
  const [dbcodea, setDbcodea] = useState('')

  const [dbcodeadecr, setDbcodeadecr] = useState('')
  const [verified, setVerified] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [executing, setExecuting] = useState(false)
  const [verifieda, setVerifieda] = useState(false)
  const [submitteda, setSubmitteda] = useState(false)
  const [executinga, setExecutinga] = useState(false)
  const [time, setTime] = useState(false)
  const [datetim, setDatetim] = useState('')
  const [decrcode, setDecrcode] = useState('')
  const [diff, setDiff] = useState('')

  const decryptCode = useQuery(DECRYPT_CODE,{
    variables: { code:codea }
  })

  const decryptDbcode = useQuery(DECRYPT_CODE,{
    variables: { code:dbcode }
  })
  //const [getUserpasscode] = useLazyQuery(GET_USERPASSCODE)  

  const getUsercode = useQuery(GET_USERPASSCODE,{
    variables: { email }
  })

  //ensin luetaan id-parametri
  const id = useParams().id




  function onChange(value) {
    console.log("Captcha value:", value);
    setTokeni(value)
    setVerified(!verified)
  }



  // eslint-disable-next-line
  const showPerson = (email) => {    
    getPerson({ 
      variables: { emailToSearch: email } 
    })  
  }


  //
  useEffect(() => {
    if(id.length>0){
      setCodea(id)
      console.log(id)
    }
  }, [id])
  //puretaan urlin id emailiksi ja ajaksi
  useEffect(() => {
    const koodi = decryptCode
    setDecrcode(koodi)
  }, [codea, decryptCode])

  //jaetaan url ajaksi ja emailiksi
  useEffect(() => {
    console.log(decrcode)
    if(decrcode.data !== undefined){
      const erotettu = decrcode.data.decryptCode.message.split('|||||')
      setDiff(erotettu)
    }
  }, [decrcode])
  //tallennetaan email ja aika
  useEffect(() => {
    console.log(diff)
    if(diff.length===2){
      setEmail(diff[0])
      setDatetim(diff[1])
    }

  }, [diff])
  //tarkastetaan aika
  useEffect(() => {
    console.log(datetim)
    if(datetim!==''){
      const aika = Date.now()
      const timeurl = Date.parse(datetim)
      const expiring = timeurl + 60*60*1000
      //const pvm = new Date(aika)
      console.log('aika: '+  aika.toString())
      console.log('expiring: '+  expiring.toString())
      console.log(datetim)

      if(aika<expiring){
        setTime(true)
      }
    }

  }, [datetim])
  /*
  //dekryptataan kannan koodi
  useEffect(() => {
    if(dbcode!==''){
      console.log(dbcode)
      let decdbcode = decryptDbcode
      console.log(decdbcode)
      if(decdbcode.data!==undefined){
        console.log(decdbcode)
        setDbcodeadecr(decdbcode.data.decryptCode.message)
      }
    }
  }, [dbcode, decryptDbcode])
*/
  //tarkastetaan täsmäävätkö koodit
  useEffect(() => {
    if(code===dbcode && code!==''){
        setCodeok(true)
    }
  }, [dbcodeadecr,code,dbcode])
  
  const [ editPassword ] = useMutation(EDIT_PASSWORD, {
    onError: (error) => {
      setError(error)
      //setError(error.graphQLErrors[0].message)
    }
  })
  // eslint-disable-next-line
  const submitcode= async (event) => {
    event.preventDefault()
    
    setCodesubmitted(true)
    //TÄHÄN dbcodea purkaminen ja sen jälkeen vertaaminen code===dbcodeadecr
    //onLogin('oleppani')
    //history.push('/')
    //haetaan kannan koodi

    console.log('käyttäjän syöttämä koodi: ' + code)
    console.log('time: '+ time)
    console.log('email: ' + email)
    let codedb = await getUsercode
    console.log(codedb.data.getUserpasscode.message)
    await setDbcode(codedb.data.getUserpasscode.message)
  
  
  
  }

  const submit = async (event) => {
    event.preventDefault()
    if((password===repass)){
      //const saltRounds = 17
      const aika = Date.now()
      const pvm = new Date(aika)
      var salt = bcryptjs.genSaltSync(12)
      const passwordhash = await bcryptjs.hash(password, salt)
      console.log(passwordhash)
     /* const user = new User({
        username: body.username,
        name: body.name,
        passwordHash,
      })
    
      const savedUser = await user.save()
    
      response.json(savedUser)
      */
      //const passwordhash = password
      //const getCode = async () => { 
    //    return get
      //} 
      editPassword({
        variables: {email, passwordhash }
      })
      
    //const saltRounds = 17
    //const passwordhash = await bcryptjs.hash(password, saltRounds)
    //const passwordhash=password
    //var salt = bcryptjs.genSaltSync(12)
    //const passwordhash = await bcryptjs.hash(password, salt)
    //console.log(passwordhash)

    setSubmitted(true)
    //onLogin('oleppani')
    //history.push('/')
  }else{
    window.confirm(t('common.passwordmismatch'))
  }
  
}
/*
console.log('datetim: '+  datetim)
console.log('time: '+  time)
console.log('submitted: ' + submitted)
console.log('codeok: '+ codeok)
console.log('id: ' + id)
console.log('codesubmitted: ' + codesubmitted)
*/

if(!time){
  return(<div>
            <h2>{t('headers.newpassword')}</h2>
            {t('common.oldlink')}
            <br /><br />
            <a href='/'>{t('common.tostart')}</a>
        </div>
        )
}
if(submitted){
      return(<div>
          <h2>{t('headers.newpassword')}</h2>
          {t('common.formok')}
          <br /><br />
          <a href='/'>{t('common.tostart')}</a>
      </div>
      )
}

if(time && codeok){
  return (
    <div>
      <form onSubmit={submit}>
        <h3>{t('headers.newpassword')}</h3>
        <div>
        {t('common.password')}<TextField
            value={password}
            type='password'
            onChange={({ target }) => setPass(target.value)}
          />
        <br /><br />
        {t('common.repassword')}<TextField
            value={repass}
            type='password'
            onChange={({ target }) => setRepass(target.value)}
          />
        </div>
        <br />
        <div align='center'>
              <ReCAPTCHA sitekey="6Lf6wLIaAAAAAORH1jwBkpv1NzwvaBYa9mpn39GP" onChange={onChange} />
          </div>
            <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} type='submit'
            disabled={(!verified)}
            executing={executing}
            submitted={submitted} >{submitted ? t('common.done') : executing ? 'Verifying' : t('common.send')}</Button>
          <br /><br />
        <a href='/register'>{t('common.registration')}</a>
        <br /><br />
        <a href='/'>{t('common.tostart')}</a>
      </form>
    </div>
  )
}
if(time && !codesubmitted){
  return (
    <div>
      <form onSubmit={submitcode}>
        <h3>{t('headers.newpassword')}</h3>
        <div>
        {t('common.code')}<TextField
            value={code}
            onChange={({ target }) => setCode(target.value)}
          />
    
        </div>
        <br />
            <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} type='submit'
             >{t('common.send')}</Button>
          <br /><br />
        <a href='/register'>{t('common.registration')}</a>
        <br /><br />
        <a href='/'>{t('common.tostart')}</a>
      </form>
    </div>
  )
}
if(!codeok){
  return(<div>
            <h2>{t('headers.newpassword')}</h2>
            {t('common.wrongcode')}
            <br /><br />
            <a href='/'>{t('common.tostart')}</a>
        </div>
        )
}
  return(<div>
            <h2>{t('headers.newpassword')}</h2>
            {t('common.failed')}
            <br /><br />
            <a href='/'>{t('common.tostart')}</a>
        </div>
      )
}

export default NewPassword